import React, { useMemo, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useForm as useFormspreeForm } from '@formspree/react'
import styled from "styled-components"
import ReCAPTCHA from "react-google-recaptcha"
import media from "../styles/media"
import { headerFontFamily } from "../styles/typography"
import Layout from "../components/Layout"
import Panel from "../components/Panel"
import Sidebar from "../components/Sidebar"
import Banner from "../components/Banner"
import parseSharpImage from "../utils/parseSharpImage"
import { UserCircleIcon, AtSymbolIcon, EnvelopeIcon } from "@heroicons/react/24/outline"
import jsxMarkdown from "../utils/jsxMarkdown"
import isEmail from 'validator/es/lib/isEmail'
import classNames from "classnames"
import {SEO} from "../components/SEO"
import DefaultPage from "../components/DefaultPage"

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  @media ${media.ltTablet} {
    flex-direction: column-reverse; 
    align-items: stretch; 
  }
`

const SidebarWrap = styled.aside`
  min-width: 350px;
  padding-left: 20px;
  flex: 0;
  @media ${media.ltLaptop} {
    min-width: 280px;
  }
  @media ${media.ltTablet} {
    padding-left: 0px;
  }
`

const Form = styled.form`
  width: 100%;
  margin-bottom: 0;
 
  input,
  textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid rgb(209 213 219);
    border-radius: 5px;
    line-height: 20px;
    padding-left: 40px;
    width: 100%;
    &::placeholder {
      color: rgb(156, 163, 175);
      opacity: 1;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
 
  .error-msg {
    font-size: 14px;
    color: var(--color-error);
    line-height: 2;
  }
  .input-error {
    border: 2px solid var(--color-error);
  }
`

const InputGroup = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 0;
`

const InputIcon = styled.div`
  position: absolute;
  top: 11px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
  svg {
    width: 20px;
    height: 20px;
    color: rgb(156, 163, 175);
  }
`

const Button = styled.button`
  font-family: ${headerFontFamily.join(', ')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0;
  padding: 12px 25px;
  line-height: 1;
  border: none;
  color: var(--color-textSecondary);
  background: var(--color-primary);
  transition: color 0.15s ease-in-out, background-color 0.15s;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  width: 114px;
  height: 42px;
  // box-shadow: -4px 7px 15px 0px rgba(0, 0, 0, 0.15);

  &:hover {
    text-decoration: none;
    text-transform: uppercase;
    background: var(--color-primary-dark);
  }
  
  &:disabled {
    opacity: 0.7;
  }
`

const SubmitError = styled.p` 
  padding: 20px;
  border-radius: 5px;
  background: rgba(202, 29, 80, 0.25);
  color: var(--color-error);
`

const SubmitSuccess = styled.p` 
  padding: 20px;
  border-radius: 5px;
  background: rgba(76,175,80,0.25);
  color: #4caf50;
`

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiContact {
        banner {
          ... on StrapiComponentHeaderImageBanner {
            title
            subTitle
            images {
              strapiId
              name
              file {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        sidebar {
          ... on StrapiComponentSidebarAnnouncementSidebar {
            nextOpeningTimeEnabled
            announcementsEnabled
          }
        }
        title
        content
      }
    }
  `)

  const {
    strapiContact: {
      banner: banners,
      sidebar,
      title,
      content,
    } = {}
  } = _.pickBy(data, (v) => v !== null)

  const { register, handleSubmit, formState, setValue, trigger } = useForm()
  const errors = useMemo(() => formState.errors, [formState])
  useEffect(() => {
    register('g-recaptcha-response', { required: true })
  }, [register])

  const [state, handleFormspreeSubmit] = useFormspreeForm(process.env.GATSBY_FORMSPREE_CONTACT_FORM)

  const [formspreeError, setFormspreeError] = useState()

  const onSubmit = data => {
    setFormspreeError(false)
    return handleFormspreeSubmit(data)
      .then((e) => { setFormspreeError(e?.response?.status !== 200) })
      .catch(() => { setFormspreeError(true) })
  }

  const panel ={
    title,
    jsxContent: (
      <>
        {content && jsxMarkdown({ content })}

        { state.succeeded && (
          <SubmitSuccess>
            Thank you, your message has been submitted. We will get back to you soon.
          </SubmitSuccess>
        )}

        { !state.succeeded && (
          <Form onSubmit={handleSubmit(onSubmit)} disabled={state.submitting}>
            <InputGroup>
              <InputIcon>
                <UserCircleIcon />
              </InputIcon>
              <input
                className={classNames({ 'input-error': errors.name })}
                type="text"
                name="name"
                placeholder="Name"
                maxLength="255"
                disabled={state.submitting}
                {...register("name" , { required: true, maxLength: 255 })}
              />
              {errors.name?.type === 'required' && <span className="error-msg">Your name is required</span>}
            </InputGroup>

            <InputGroup>
              <InputIcon>
                <AtSymbolIcon />
              </InputIcon>
              <input
                className={classNames({ 'input-error': errors.email })}
                type="text"
                name="email"
                placeholder="Email"
                maxLength="255"
                disabled={state.submitting}
                {...register("email" , { required: true, maxLength: 255, validate: isEmail })}
              />
              {errors.email?.type === 'required' && <span className="error-msg">Your email is required</span>}
              {errors.email?.type === 'validate' && <span className="error-msg">Enter a valid email address</span>}
            </InputGroup>

            <InputGroup>
              <InputIcon>
                <EnvelopeIcon />
              </InputIcon>
              <textarea
                className={classNames({ 'input-error': errors.message })}
                rows={4}
                name="message"
                defaultValue=''
                placeholder="Message (Max. 5000 characters)"
                maxLength="500"
                disabled={state.submitting}
                {...register("message" , { required: true, maxLength: 255 })}
              />
              {errors.message?.type === 'required' && <span className="error-msg">Your message is required</span>}
            </InputGroup>

            <InputGroup>
              <ReCAPTCHA
                sitekey="6LfEJskgAAAAACIR0hTqEj6fBJluOElEdWKlnnTJ"
                onChange={(v) => {
                  setValue('g-recaptcha-response', v)
                  return trigger('g-recaptcha-response')
                }}
              />
              {errors['g-recaptcha-response']?.type === 'required' && <span className="error-msg">Please check the box above</span>}
            </InputGroup>

            { formspreeError && (
              <SubmitError>
                An error occurred whilst trying submit your message. Please try again.
              </SubmitError>
            )}

            <Button type="submit" disabled={state.submitting}>Submit</Button>
          </Form>
        )}
      </>
    ),
  }

  return (
    <DefaultPage
      banners={banners}
      sidebar={sidebar}
      parsedPanels={[panel]}
    />
  )
}

export default Index

export const Head = ({ location }) => (
  <SEO title="Contact" pathname={location.pathname} />
)
